import React, { useState } from "react"
import Input from "../components/molecules/input"
import { useForm } from "react-hook-form"
import { Controller } from "react-hook-form"
import { NextCreateableSelect } from "../components/molecules/select/next-select"
import Button from "../components/fundamentals/button"

const ProgressStepBar = ({ percent }) => {
  console.log(percent)
  return (
    <div className="flex justify-between relative">
      <div
        className={`absolute h-[4px] bg-violet-60 top-0 bottom-0 my-auto z-10 w-[${percent}%]`}
      ></div>
      <div className="absolute h-[4px] bg-violet-20 top-0 bottom-0 my-auto w-full"></div>
      <div className="rounded-full w-12 h-12 flex items-center justify-center z-10 text-white bg-violet-60">
        1
      </div>
      <div className="rounded-full w-12 h-12 flex items-center justify-center z-10 text-white bg-violet-60">
        2
      </div>
      <div className="rounded-full w-12 h-12 flex items-center justify-center z-10 text-white bg-violet-60">
        3
      </div>
    </div>
  )
}

const Onboarding = () => {
  const [percent, setPercent] = useState<number>(0)
  const { control, setValue } = useForm({})
  return (
    <div className="w-[70%] m-auto mt-[100px]">
      <ProgressStepBar percent={percent} />

      {percent === 0 && (
        <div className=" mt-10">
          <div className="flex justify-center">
            <h1 className="text-2xl	font-bold">Let's go to know each other</h1>
          </div>
          <div className="flex justify-between mt-10">
            <div className="w-[45%]">
              <Input placeholder="" type={"text"} label="Your first name" />
              <div className="h-[30px]"></div>
              <Controller
                name={"type"}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <NextCreateableSelect
                      label="Your store country"
                      onChange={onChange}
                      options={[]}
                      value={value || null}
                      placeholder="Select country..."
                      // onCreateOption={[]}
                      isClearable
                    />
                  )
                }}
              />
              <div className="h-[30px]"></div>
              <Input
                placeholder="Phone"
                type={"text"}
                label="How should we contact you?"
              />
            </div>
            <div className="w-[45%]">
              <Input placeholder="" type={"text"} label="Enter last name" />
              <div className="h-[30px]"></div>
              <Controller
                name={"type"}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <NextCreateableSelect
                      label="Your person location"
                      onChange={onChange}
                      options={[]}
                      value={value || null}
                      placeholder="Select location..."
                      // onCreateOption={[]}
                      isClearable
                    />
                  )
                }}
              />
              <div className="h-[30px]"></div>
              <label className="inter-small-semibold text-grey-50">
                Your personal social profile (optional)
              </label>
              <div className="flex justify-between">
                <div className="w-[30%]">
                  <NextCreateableSelect
                    label=""
                    // onChange={onChange}
                    options={[]}
                    value={null}
                    placeholder="Facebook"
                    // onCreateOption={[]}
                    isClearable
                  />
                </div>
                <div className="w-[65%]">
                  <Input
                    placeholder="www.facebook.com/abc"
                    type={"text"}
                    label=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {percent === 50 && <div>2</div>}
      {percent === 100 && <div>3</div>}
      <div className="flex justify-center mt-20">
        <Button
          className="rounded-rounded mt-4 w-[320px] inter-base-regular"
          variant="primary"
          size="large"
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default Onboarding
